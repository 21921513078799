import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import Text from 'components/common/typeography/text/Text';
import Switch from 'components/common/form/switch/Switch';
import PaymentAtLaterAge from './components/payment-at-later-age/PaymentAtLaterAge';
import yup from 'lib/validation';
import {
  currencyField,
  getAdvancedOptionsFieldDefaultValidation,
  getFundAccessValidation,
} from 'lib/validation/validation';
import {useTranslation} from 'lib/i18n';
import annualImg from 'assets/images/products/hecm.svg';
import AnnualRatesSelect from 'components/products/annual/AnnualRatesSelect';
import FundAccessAnnual from 'components/products/annual/components/FundAccess/FundAccess';
import {map} from 'lodash';
import {isTenurePayment} from 'lib/product';
import {useIllustrationContext} from 'contexts/illustration-context';

const additionalStepValidation = yup.object({
  Margin: yup.string(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.ANNUAL], 'value')}),
  TenureResult: yup.object().when('FundAccess', {
    is: FUND_ACCESS.MONTHLY_ANNUAL_PAYMENTS,
    then: (schema) =>
      schema.shape({
        ConversionAge: yup.number().when('BorrowerProfile', function (age, schema, context) {
          const {BorrowerProfile: {Age} = {}} = context.originalValue || {};

          return Age ? schema.min(Age) : schema;
        }),
        MaxMonthlyPayment: currencyField({withRequired: false}),
      }),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
});

export function useAnnualHook(props = {}) {

  const {t} = useTranslation();

  const {
    product: {
      calculation: {
        FundAccess,
        TenureResult: {MaxMonthlyPayment, ConversionAge} = {},
        BorrowerProfile: {Age} = {},
        OverrideClosingCosts,
        OverrideClosingCostsKey,
        ClosingCost
      } = {},
    } = {},
  } = props;

  const [isDisabled, setIsDisabled] = useState(true);
  const [maxClosingCost, setMaxClosingCost] = useState(ClosingCost);
  const [isFundAccessLumpSum, setIsFundAccessLumpSum] = useState(false);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts)
    if(!OverrideClosingCosts){
      setMaxClosingCost(ClosingCost);
    }
  },[OverrideClosingCostsKey]);

  useEffect(() => {
    if(FundAccess == FUND_ACCESS.LUMP_SUM){
      setIsFundAccessLumpSum(true);
    }
    else{
      setIsFundAccessLumpSum(false);
    }
    
  },[FundAccess]);

  const toggleDisabled = useCallback(
    (event) => {
      setIsDisabled(!event);
    },
    [],
  );

  const illustration = useIllustrationContext();
  const formid = `illustration-${illustration.id}`;

  const submitClick= (event) => {
    const formButton = document.querySelector(`form[id="${formid}"] button[type="submit"]`);
    formButton.click();
  };

  let tenurePayment = useMemo(() => {
    let validation = yup.number().nullable(true).min(0);

    if (MaxMonthlyPayment) validation = validation.max(MaxMonthlyPayment);

    return validation;
  }, [MaxMonthlyPayment]);

  const maxClaimAmount = (props?.product?.calculation?.HecmMetric?.MaxClaimAmount) 
    ? formatCurrency(props?.product?.calculation?.HecmMetric?.MaxClaimAmount) 
    : '$0.00';

  const description = t('illustratorTranslation:illustration.products.annual.description')
    .replace('<max claim amount>', maxClaimAmount);

  // return the final product data format, including extras
  return {
    description: description,

    img: annualImg,
    imgText: "Adjustable",
    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'locYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.locYearEnd),
          minWidth: 100,
          listData: 'locYearStart',
          condition: !isTenurePayment({FundAccess}),
        },
        {
          key: 'homeValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 3,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [FundAccess],
    ),
    lifetimeModelChartLines: useMemo(
      () => [
        {
          key: 'locYearEnd',
          condition: !isTenurePayment({FundAccess}),
        },
      ], [FundAccess],
    ),
    additionalAdvancedOptionHeader: useMemo(
      () => [
        {
          key: 'rateAdjustments',
          group: 1,
          editable: true,
          formatter: ({row}) => formatPercent(row.rateAdjustments),
          minWidth: 100,
        },
        {
          key: 'locYearEnd',
          group: 2,
          formatter: ({row}) => formatCurrency(row.locYearEnd),
          listData: 'locYearStart',
          condition: !isTenurePayment({FundAccess}),
        },
        {
          key: 'tenurePayment',
          group: 2,
          formatter: ({row}) => formatCurrency(row.tenurePayment),
          editable: ({age}) => ConversionAge < age,
          condition: isTenurePayment({FundAccess}),
        },
      ],
      [ConversionAge, FundAccess],
    ),
    additionalStepTitle: t('illustratorTranslation:calc.annual.details.benefits'),
    additionalStepValidation: useMemo(
      () =>
        additionalStepValidation.shape({
          advancedOptions: getAdvancedOptionsFieldDefaultValidation({
            ApiRoute: API_ROUTES.ANNUAL,
            annualPayment: yup.number(),
            monthlyPayment: yup.number(),
            tenurePayment,
          }),
        }),
      [tenurePayment],
    ),
    additionalQuickFill: useMemo(
      () => [
        <AnnualRatesSelect order="1" name="Margin" />,
        <Input order="3" name="CashAtClose" type="currency" defaultValue={0} disabled ={isFundAccessLumpSum} />,
        <Input order="4" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <div order = "5">{!isDisabled && <Text order = "5" variant="caption" colorVariant="grey">Max closing costs {formatCurrency(maxClosingCost)}</Text>}</div>,
        <Switch order="6" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
      ],
      [isDisabled, isFundAccessLumpSum],
    ),
    additionalStep: useMemo(
      () => [
        <FundAccessAnnual ApiRoute={API_ROUTES.ANNUAL} order="2" onClick={submitClick}/>,
        <PaymentAtLaterAge defaultAge={Age} order="8" />,
      ],
      [Age],
    ),
  };
}
