import {makeStyles} from '@material-ui/core/styles';
import {isMdToLgQuery, isMobileAppQuery, isSmSizeAppQuery, isTabletAppQuery} from 'lib/app-theme';
import {fontWeightMedium, fontWeight600} from 'lib/mui-config';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxHeight: 200,
      minWidth: 200,
      alignItems: 'center',
    },
    hide: {
      display: 'none',
    },
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
    m2: {
      margin: theme.spacing(2),
      [isSmSizeAppQuery(theme)]: {margin: theme.spacing(1)},
      [isMobileAppQuery(theme)]: {margin: theme.spacing(0)},
    },
    paymentBreakdownGridContainer: {
      [isMobileAppQuery(theme)]: {
        flexDirection: 'column',
      },
    },
    flexElement: {
      display: 'flex',
    },
    smPieChartElement: {
      margin: 'auto',
    },
    smPieChart: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'space-around',
    },
    
    smPieChardLegendFullPage: {
      minWidth: '100%',
    },
    smPieChartLegend: {
      '& .MuiGrid-root-152>.MuiBox-root-270': {
        [isTabletAppQuery(theme)]: {margin: theme.spacing(1, 'auto', 1, 1)},
        [isMdToLgQuery(theme)]: {
          marginLeft: theme.spacing(3),
        },
      },
      minWidth: theme.forPrint('unset !important'),
    },
    smPieChartLegendDummy: {
      visibility: 'hidden',
    },
    smPieChartItem: {
      display: 'flex',
      paddingLeft: 0 ,
      marginLeft: theme.spacing(-5),
      [isSmSizeAppQuery(theme)]: {marginLeft: theme.spacing(-3)},
    },
    spcContainer: {},
    container: {
      padding: theme.spacing(2, 3),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(1)},
    },
    summaryContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    summaryImage: {
      margin: theme.spacing(4),
      marginBottom: theme.spacing(-1),
      align: 'left',
    },
    summaryText: {
      fontWeight: fontWeight600,
      margin: theme.spacing(4),
      minHeight: theme.spacing(5),
      marginBottom: theme.spacing(-1),
      marginLeft: theme.spacing(-1),
      [isSmSizeAppQuery(theme)]: {marginLeft: theme.spacing(0), fontSize: '22px', marginTop: theme.spacing(4)},
    },
    summarySysCreateDate: {
      margin: theme.spacing(4),
      paddingRight: theme.spacing(5),
      [isSmSizeAppQuery(theme)]: {marginLeft: theme.spacing(3), marginBottom: theme.spacing(2), marginTop: theme.spacing(1)},
      marginBottom: theme.spacing(-1),
      marginLeft: 'auto',
      textAlign: 'right',
      color: theme.palette.borrower.dark,
      fontStyle: 'normal',
      fontWeight: fontWeightMedium,
      fontSize: theme.withPrint(theme.spacing(1.5), theme.spacing(2.5)),
    },
    breakdownTitle: {
      margin: theme.spacing(3),
      marginBottom: theme.spacing(-6),
      [isSmSizeAppQuery(theme)]: {margin: theme.spacing(1)},
      [isMobileAppQuery(theme)]: {margin: theme.spacing(0)},
      marginLeft: theme.spacing(1),
    },
    graphSectionContainer: {
      padding: theme.spacing(2, 3),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(1)},
      marginBottom: theme.spacing(-4),
    },
  }),
  {index: 1},
);

export default useStyles;
