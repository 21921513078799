import {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {parentheses} from 'lib/utils';
import {isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';

export function useSecondOverallResults(props = {}) {
  const {
    product: {
      ApiRoute,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          OtherLiens,
        } = {},
        PrincipalLimit,
        ClosingCost,
        SimplifiedCashToBorrower,
        CashAtClose,
        Rate,
        ServiceFee,
        InitialLoanBalance,
        UnutilizedCash,
      } = {},
    } = {},
  } = props;
  const {t, override, cashLabel} = useTranslation();

  const availableCashSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.total proceeds'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.total proceeds tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'availableCashSection-total-proceeds',
        highlight: false,
      },
      {
        label: override('illustratorTranslation:illustration.overall results.2nd mortgage liens',{
          product: {ApiRoute},
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.2nd mortgage liens tooltip', {
          product: {ApiRoute},
        }),
        value: parentheses(formatCurrency(OtherLiens)),
        useColorOptions: {value: 'red'},
        order: 3,
        id: 'availableCashSection-2nd-mortgage-balance-liens',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.second.estimated closing costs tooltip'),
        value: parentheses(formatCurrency(ClosingCost)),
        useColorOptions: {value: 'red'},
        order: 4,
        id: 'availableCashSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.available cash'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.available cash tooltip', {
          product: {ApiRoute},
        }),
        value: formatPaymentBreakdownValue(SimplifiedCashToBorrower),
        useColorOptions: {value: isPaymentBreakdownValueNegative(SimplifiedCashToBorrower) ? 'red' : undefined},
        order: 5,
        id: 'availableCashSection-available-cash',
        highlight: true,
      },
    ],
    [t, override, ApiRoute, PrincipalLimit, OtherLiens, ClosingCost, SimplifiedCashToBorrower],
  );

  const initialLoanBalanceSection = useMemo(
    () => [
      {
        label: override('illustratorTranslation:illustration.overall results.2nd mortgage liens payoff',{
          product: {ApiRoute},
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.2nd mortgage liens payoff tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(OtherLiens),
        order: 2,
        id: 'initialLoanBalanceSection-2nd-mortgage-liens-payoff',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.second.estimated closing costs tooltip'),
        value: formatCurrency(ClosingCost),
        order: 3,
        id: 'initialLoanBalanceSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.requested cash', {
          cash: CashAtClose,
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.requested cash tooltip', {
          product: {ApiRoute},
        }),
        value: formatPaymentBreakdownValue(CashAtClose),
        order: 4,
        id: 'initialLoanBalanceSection-requested-cash',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial loan balance'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.initial load balance tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(InitialLoanBalance),
        order: 5,
        id: 'initialLoanBalanceSection-initial-loan-balance',
        highlight: true,
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.unutilized cash', {
          cash: UnutilizedCash,
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.unutilized cash tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(UnutilizedCash), 
        order: 6,
        id: 'initialLoanBalanceSection-unutilized-cash',
        highlight: false,
      },
    ],
    [override, ApiRoute, Liens, t, ClosingCost, cashLabel, CashAtClose, InitialLoanBalance, UnutilizedCash],
  );

  const homeInformationSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.estimated home value'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.est home value tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(ValueOfProperty),
        order: 2,
        id: 'homeInformationSection-estimated-home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.current mortgage liens'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.current mortgage liens tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(Liens),
        order: 3,
        id: 'homeInformationSection-current-mortgage-liens',
      },
    ],
    [t, override, ApiRoute, ValueOfProperty, Liens],
  );

  const youngestBorrowerSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.birth date'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.birthdate tooltip', {
          product: {ApiRoute},
        }),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 2,
        id: 'youngestBorrowerSection-birth-date',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.nearest age'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.nearest age tooltip', {
          product: {ApiRoute},
        }),
        value: Age,
        order: 3,
        id: 'youngestBorrowerSection-nearest-age',
      },
    ],
    [t, override, ApiRoute, DateOfBirth, Age],
  );

  const loanDetailsSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.rate'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.rate tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(Rate),
        order: 2,
        id: 'loanDetailsSection-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.service fee'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.second.service fee tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(ServiceFee),
        order: 4,
        id: 'loanDetailsSection-service-fee',
      },
    ], [t, override, ApiRoute, Rate, ServiceFee]
  );

  return {
    availableCashSection,
    initialLoanBalanceSection,
    homeInformationSection,
    youngestBorrowerSection,
    loanDetailsSection,
  };
}
